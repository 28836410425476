@media (min-width: 1240px) {
    .encompass {
        width: 1200px; }

    .body {
        max-width: 820px;
        text-align: center; }

    .body_wrap {
        max-width: 860px; }

    .home {
        max-width: 680px;
        width: 680px;
        display: inline-block; } }

@media (min-width: 1440px) {
    .encompass {
        width: 1400px; }

    .body {
        max-width: 1020px;
        text-align: center; }

    .body_wrap {
        max-width: 1060px; }

    .home {
        max-width: 680px;
        display: inline-block; } }

@media (max-width: 1100px) {
    @media (min-width: 900px) {
        .menu {
            width: 200px!important; }

        .encompass {
            width: 960px; } } }

@media (max-width: 1000px) {
    .menu {
        display: none; }

    .encompass {
        width: 680px; } }


@media (min-width: 1001px) {
    .home {
        .header {
            display: none; } }

    // .body
    //     padding-top: 60px

    .main_logo {
        margin-top: 30px; }

    .encompass {
        .plus_button {
            bottom: 20px; } } }

@media (max-width: 1001px) {
    .encompass {
        margin: 0px;
        height: 100vh;
        width: 680px; }

    .body {
        margin: 0px;
        height: calc(100vh - 40px); }

    .body_wrap {
        height: 100vh; }

    .main_logo {
        margin-top: 60px; }

    .body_wrap {
        .footer_menu {
            display: block; } }

    .padding_bottom {
        padding-bottom: 80px; } }
