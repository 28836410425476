// Import variable files
@import "components/variables";
@import "components/scrollbar/scrollbar";

// Import authentication styles
@import "components/auth/auth";

@import "components/video/video";

@import "components/loading/loading";

@import "components/modal/modal";

@import "components/feed/post";

@import "components/main/main";

@import "components/account/account";

@import "components/menu/menu";

@import "components/positions/positions";

@import "components/main/footer";

@import "components/main/hover";

@import "admin/admin";

@import "admin/users/users";
@import "admin/users/user_info";

@import "admin/posts/posts";
@import "components/main/buttons";

@import "components/feed/comments";
@import "components/user/_endorsements";

body {
    background-color: $primary;
    text-align: center;
    margin: 0px;
    overflow: hidden; }

.encompass {
    box-shadow: 0px 0px 20px 1px #00000080;
    height: calc(100vh - 40px);
    margin: 20px 0px;
    display: inline-block;
    width: 1060px;
    max-width: 100%;
    overflow: hidden; }

.body, .body_wrap {
    max-width: 680px;
    width: calc(100% - 40px);
    display: inline-block;
    text-align: left;
    padding: 20px;
    background-color: $secondary;
    overflow-y: overlay;
    height: calc(100vh - 80px);
    margin: 00px 0px;
    vertical-align: top; }

.body_wrap {
    padding: 0px;
    width: calc(100% + 1px);
    height: calc(100vh - 40px);
    max-width: 720px;
    position: relative;
    overflow: hidden; }

@import "components/main/responsive";
