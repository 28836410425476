// Colours
$primary: #cba42d;
$secondary: #292929;
$quarternary: #e9e9e9;
$quinary: #5a5a5a;
$danger: #970000;

// fonts
$sans: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
// $serif:
$name: 'Quicksand', sans-serif;
