.body_wrap {
    .footer_menu {
        width: calc(100% + 1px);
        height: 60px;
        background-color: $quarternary;
        box-shadow: 0px 0px 20px 1px #000000;
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: none;
        padding: 10px 0px;
        transition: 0.2s ease-in-out all;

        .footer_item {
            text-align: center;
            display: inline-block;
            width: calc(100% / 3 - 2px);
            line-height: 60px;
            font-size: 40px; }

        .footer_item:nth-child(2) {
            border-left: 1px solid $secondary;
            border-right: 1px solid $secondary; } } }
