.home {
    .post {
        position: relative;
        margin: 20px 0px;
        color: black;

        .bubble_icon {
            position: absolute;
            top: 10px;
            right: 10px;
            color: white;
            font-size: 25px; } } }
