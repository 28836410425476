.account {
    font-family: $sans;

    .heading {
        color: $primary;
        font-size: 30px; }

    .content {
        color: $quarternary;
        font-size: 24px; }

    .section {
        margin-bottom: 30px; } }
