.menu {
    max-width: 680px;
    width: 300px;
    display: inline-block;
    text-align: left;
    margin: 0px 0px;
    padding: 20px;
    background-color: $quarternary;
    // box-shadow: 0px 0px 20px 1px #00000080
    height: calc(100vh - 80px);
    overflow-y: auto;
    text-align: center;
    vertical-align: top;

    hr {
        border: none;
        height: 1px;
        background-color: $primary;
        margin: 40px 0px; }

    .item {
        font-family: $name;
        font-size: 24px;
        // letter-spacing: 1px
        margin: 20px 0px;
        text-align: left;
        cursor: pointer;
        vertical-align: middle; }

    .menu_icon {
        display: inline-block;
        width: 50px;
        vertical-align: middle; }

    .menu_icon:nth-child(n) {
        display: inline-block; }

    .logo {
        width: 80px; }

    .heading {
        font-size: 32px;
        font-family: $name;
        color: black;
        margin-bottom: 20px;
        letter-spacing: 2px; } }

.home {
    text-align: center;
    position: relative;

    .page_heading {
        color: $quarternary;
        font-family: $name;
        font-size: 40px;
        margin-bottom: 40px;
        text-shadow: 0px 5px 10px black;
        text-transform: capitalize; }

    .menu, .body, .post, .account {
        text-align: left; }

    .header {
        position: fixed;
        width: 660px;
        max-width: calc(100% - 20px);
        text-align: left;
        color: $quarternary;
        background-color: $secondary;
        padding: 10px;
        margin-top: -20px;
        margin-left: -20px;
        z-index: 3;
        box-shadow: 0px 0px 20px 1px #00000080;
        font-family: $sans;
        font-size: 30px;

        .burger {
            float: right;
            margin-top: 3px; } } }

.encompass {
    position: relative;

    .plus_button {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        // border: 1px solid $secondary
        background-color: $quarternary;
        color: $primary;
        box-shadow: 0px 10px 20px 1px #000000;
        position: absolute;
        bottom: 100px;
        right: 20px;
        font-size: 70px;
        line-height: 70px;
        cursor: pointer;
        transition: 0.2s ease-in-out all; } }
