.position {
    background-color: $quarternary;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 0px;
    text-align: left;
    font-family: $sans;
    margin: 20px 0px;
    position: relative;
    box-shadow: 0px 0px 20px 1px #00000080;

    .user {
        display: inline-block; }

    .title {
        font-size: 30px;
        font-family: $name; }

    .location {
        font-size: 20px;
        color: $primary;
        font-weight: 700;
        margin-bottom: 20px; }

    .description {
        font-size: 18px; }

    .audio {}

    .bubble_icon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px; } }
