.user_wrap {
    border: 1px solid $primary;
    margin: 5px 0px;

    .user {
        font-family: $sans;
        cursor: pointer;
        padding: 10px;

        .user_item {
            display: inline-block;
            color: $quarternary;
            width: calc((100% - 50px) / 3);
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis; }

        .user_item:nth-child(1) {
            width: 50px; } }

    .user:hover {
        background-color: #adadad; }

    .extend {
        overflow: hidden;
        transition: 0.2s ease-in-out all;

        .user_item {
            color: $quarternary;
            text-align: left;
            font-family: $sans;

            td {
                padding: 10px;
                vertical-align: top; } } } }

.search_bar {
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 30px;
    border: 1px solid $primary;
    background-color: $quarternary;
    color: black;
    outline: none;
    margin-bottom: 20px;
    width: calc(100% - 40px); }

.search_icon {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 30px;
    color: $secondary;
    transform: rotate(90deg); }
