.empty {
    color: $quarternary;
    font-size: 20px;
    font-family: $name; }

.post {
    background-color: $quarternary;
    border: 1px solid $quarternary;
    border-radius: 5px;

    .content {
        padding: 10px;
        font-size: 16px;
        font-family: $sans;

        .user {
            font-weight: 700;
            margin-bottom: 10px; }

        .reports {
            margin-top: 10px;

            .report {
                display: inline-block;
                padding: 5px;
                padding-bottom: 2px;
                margin: 5px;
                border-radius: 5px;
                background-color: $quinary;
                border-bottom: 5px solid $danger; } } }

    .post_footer {
        font-size: 18px;
        font-family: $sans;

        .action {
            display: inline-block;
            width: calc((100% - 38px) / 2);
            text-align: center;
            padding: 10px;
            margin-bottom: -5px;
            margin-top: 0px;
            color: $quarternary;
            font-size: 20px;
            cursor: pointer;

            &:nth-child(1) {

                background-color: $primary;
                margin-left: -1px;
                border-bottom-left-radius: 5px; }

            &:last-child {
                background-color: $danger;
                margin-right: -1px;
                border-bottom-right-radius: 5px; } } } }
