.comments_wrap {
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &.full {
        transition: max-height 0.2s ease-in-out; } }

.comments {
    // background-color: $quinary
    margin-top: -10px;
    padding: 10px;
    padding-top: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    z-index: 1;
    transition: 0.2s ease-in-out all;

    .send_box {
        position: relative;

        .comment_input {
            display: inline-block;
            width: calc(100% - 25px);
            line-height: 30px;
            font-size: 18px;
            border-radius: 30px;
            padding: 5px;
            padding-left: 20px;
            border: none;
            outline: none; }

        .send_icon {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px; } }

    .comment, .user_comment {
        background-color: white;
        text-align: left;
        padding: 10px;
        display: inline-block;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 5px;
        // border-top-left-radius: 0px
        position: relative;
        z-index: 1;
        font-family: $sans;
        box-shadow: 0px 5px 10px 1px #00000080;

        .comment_content {
            margin-top: 5px; } }

    .comment {

        .speech {
            color: white;
            position: absolute;
            top: 0px;
            left: -14px;
            font-size: 30px;
            z-index: 0; } }

    .user_comment {
        background-color: #c4c4c4;
        // float: right

        .speech {
            color: #c4c4c4;
            position: absolute;
            top: 0px;
            right: -14px;
            font-size: 30px;
            z-index: 0; } } }
