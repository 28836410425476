.user_info {
    width: 680px;
    max-width: calc(100% - 40px);
    display: inline-block;
    padding: 0px 20px;

    .heading {
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px; }

    .info {
        font-size: 18px;
        text-align: left; } }
