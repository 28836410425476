// This file details hover events
// These will only be triggered if the device allows for hover
// This prevents the hover from effecting touch controls

@media (hover: hover) and (pointer: fine) {
    .auth {
        .login-form {
            button:hover {
                transform: scale(1.05); }

            .link:hover {
                border-bottom: 1px solid $primary; } } }

    .body_wrap {
        .footer_menu {
            .footer_item:hover {
                opacity: 0.6; } } }

    .menu {
        .item:hover {
            opacity: 0.6; } }

    .encompass {
        .plus_button:hover {
            transform: scale(1.1); } }

    .slidemenu {
        .item:hover {
            opacity: 0.6; } }

    .modal_x:hover {
    	color: rgba($secondary, 0.6); } }
