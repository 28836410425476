.auth-body {
    max-width: 680px;
    width: calc(100% - 40px);
    display: inline-block;
    text-align: left;
    padding: 20px;
    background-color: $secondary;
    overflow-y: auto;
    margin: 20px 0px;
    box-shadow: 0px 0px 20px 1px #00000080; }

@media (max-width: 680px) {
    .auth-body {
        margin: 0px;
        height: 100vh; } }

.auth {
    font-size: 50px;
    color: $primary;
    text-align: center;

    .logo {
        width: 100px; }

    .login-form {
        background-color: $quarternary;
        padding: 40px;
        vertical-align: middle;
        border-radius: 5px;
        text-align: center;
        font-family: $sans;

        .error {
            font-size: 15px;
            color: $danger;
            height: 15px;
            margin-top: -20px; }

        input, select {
            font-size: 20px;
            padding: 10px;
            border: 1px solid #c4c4c4;
            border-bottom: 1px solid $primary;
            display: inline-block;
            width: 278px;
            max-width: calc(100% - 22px);
            transition: 0.2s ease-in-out all;
            height: 23px; }

        .phone_input {
            width: 199px;
            max-width: calc(100% - 101px);
            margin-left: -1px; }

        select {
            font-size: 15px;
            width: 80px;
            height: 45px;
            vertical-align: bottom;
            outline: none;

            /* Chrome, Safari, Edge, Opera */ }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield; }

        input:focus {
            // border-bottom: 1px solid green
            outline: none; }

        button {
            font-size: 20px;
            padding: 10px;
            text-align: center;
            width: 300px;
            max-width: 100%;
            border-radius: 0px;
            border: none;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 100;
            outline: none;
            cursor: pointer;
            font-family: $name; }

        .primary {
            background-color: $primary;
            color: $quarternary; }

        .secondary {
            background-color: $quarternary;
            color: $primary;
            border: 1px solid $primary; }

        hr {
            margin-bottom: 10px;
            border: none;
            height: 1px;
            background-color: $primary; }

        .small_text {
            font-size: 15px;
            color: black;
            margin-top: 30px;
            display: inline-block;
            cursor: default; }

        .link {
            color: $primary;
            font-weight: 700;
            cursor: pointer;
            margin-top: 0px; } } }
