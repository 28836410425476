.loading {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    background-color: rgba($primary, 0.6);
    z-index: 100;

    .loader1 {
        position: absolute;
        top: calc(50% - 52px);
        left: calc(50% - 52px);
        border: 2px solid black;
        border-bottom: 2px solid transparent;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        animation: spin 0.8s infinite;
        animation-timing-function: linear; }

    .loader2 {
        position: absolute;
        top: calc(50% - 57px);
        left: calc(50% - 57px);
        border: 2px solid black;
        border-bottom: 2px solid transparent;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        animation: antispin 1s infinite;
        animation-timing-function: linear; }

    .loader3 {
        position: absolute;
        top: calc(50% - 62px);
        left: calc(50% - 62px);
        border: 2px solid black;
        border-bottom: 2px solid transparent;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        animation: spin 2s infinite;
        animation-timing-function: linear; } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

@keyframes antispin {
    0% {
        transform: rotate(360deg); }

    100% {
        transform: rotate(0deg); } }
