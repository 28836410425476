.verify_btn, .delete_btn {
    display: inline-block;
    width: calc((100% - 20px) / 2);
    text-align: center;
    padding: 10px;
    margin-bottom: -10px;
    margin-top: 20px;
    color: $quarternary;
    font-size: 20px;
    cursor: pointer; }

.verify_btn {
    background-color: $primary;
    margin-left: -10px;
    border-bottom-left-radius: 5px; }

.delete_btn {
    background-color: $danger;
    margin-right: -10px;
    border-bottom-right-radius: 5px; }
